<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"/>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-dept-multi type="search" label="LBLDEPT" name="deptList" v-model="searchParam.deptList"/>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="float-right gridbtntop" style="margin-left:auto;">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
        <div class="col-12" style="background:#fff;">
          <apexchart 
            ref="chart" 
            height="700" 
            :options="chart.chartOptions" 
            :series="chart.series"></apexchart>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'testimonial-person-graph1',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        deptCd: '',
        deptList: '',
        startYmd: '',
        endYmd: '',
      },
      chart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '부서별 유소견자 수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.hea.checkup.suspect.graph.dept.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.chart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'deptName');
        this.chart.series = [{data: this.$_.map(_result.data, 'deptCount'), name: '유소견자 수'}];
      
        this.$refs['chart'].refresh();
        this.chart.chartWidth = '90%';
      },);
    },
  }
};
</script>